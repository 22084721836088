.account {
  margin: 0 auto;
  width: 80%;
}

.account form {
  padding-bottom: 15px;
}

.header {
  margin-bottom: 10px;
  float: right;
}

.form-body {
  clear: both;
}

.account .form-group .control-label {
  font-size: 16px !important;
}

.modal-text {
  color: black !important;
}

#contained-modal {
  color: black;
}